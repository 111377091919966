<script>
  // # # # # # # # # # # # # #
  //
  //  USERNAME DIALOG
  //
  // # # # # # # # # # # # # #
  // *** IMPORTS
  import { onMount, createEventDispatcher } from "svelte"
  import { fade } from "svelte/transition"
  import { quartOut } from "svelte/easing"
  import sample from "lodash/sample.js"

  // *** STORES
  import { localUserName } from "../stores.js"
  import { urlFor } from "../sanity.js"

  // *** CONSTANTS
  const dispatch = createEventDispatcher()

  // *** VARIABLES
  let username = $localUserName ? $localUserName : ""
  let stepTwo = false

  // *** DOM REFERENCES
  let inputEl = {}

  // *** AVATARS
  export let avatars = []
  let selectedAvatar = sample(avatars)._id

  onMount(async () => {
    if (inputEl) {
      inputEl.focus()
    }
  })
</script>

{#if stepTwo}
  <div
    class="username-dialog"
    transition:fade={{ duration: 400, easing: quartOut }}
  >
    <div class="box avatar-picker">
      <div class="header">Select your avatar</div>
      <div class="inner">
        {#each avatars as avatar, i}
          <div
            class="avatar"
            class:selected={selectedAvatar == avatar._id}
            on:click={() => {
              selectedAvatar = avatar._id
            }}
          >
            <img src={urlFor(avatar.front[0].asset).url()} />
          </div>
        {/each}
      </div>
      <button
        on:click={e => {
          dispatch("username", { username: username, avatar: selectedAvatar })
        }}>Enter</button
      >
    </div>
  </div>
{:else}
  <div
    class="username-dialog"
    transition:fade={{ duration: 400, easing: quartOut }}
  >
    <div class="box">
      <input
        type="text"
        bind:this={inputEl}
        bind:value={username}
        placeholder="Choose your username"
        on:keydown={e => {
          if (e.key == "Enter") stepTwo = true
        }}
      />
      <button
        on:click={e => {
          stepTwo = true
        }}>Enter</button
      >
    </div>
  </div>
{/if}

<style lang="scss">.username-dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: black;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100000; }
  .username-dialog .box {
    font-family: "IBM Plex Mono", monospace;
    padding: 40px;
    font-size: 18px;
    text-align: center;
    user-select: none;
    border-radius: 4px;
    background: #f0f0f0;
    display: flex; }
    @media (max-width: 800px) {
      .username-dialog .box {
        font-size: 12px; } }
    .username-dialog .box input {
      font-family: "IBM Plex Mono", monospace;
      font-size: 14px;
      float: left;
      width: calc(100% - 60px);
      display: block;
      background: #c5c5c5;
      border: 1px solid black;
      color: black;
      border-radius: 4px;
      padding: 12px 18px;
      outline: none;
      height: 30px;
      margin-right: 24px; }
      .username-dialog .box input.smaller {
        width: calc(100% - 110px); }
      .username-dialog .box input:focus {
        border: 1px solid #f0f0f0; }
      .username-dialog .box input::placeholder {
        opacity: 0.7; }
      .username-dialog .box input input[type="text"] {
        -webkit-appearance: none; }
        .username-dialog .box input input[type="text"]::placeholder {
          color: #f0f0f0; }
      .username-dialog .box input select {
        -webkit-appearance: none; }
    .username-dialog .box.avatar-picker {
      flex-direction: column;
      align-items: center; }
      .username-dialog .box.avatar-picker .header {
        font-family: "IBM Plex Mono", monospace;
        color: black;
        font-size: 14px; }
      .username-dialog .box.avatar-picker .inner {
        display: flex;
        flex-wrap: wrap;
        margin-top: 20px;
        margin-bottom: 20px; }
        .username-dialog .box.avatar-picker .inner .avatar {
          cursor: pointer;
          padding: 5px;
          border: 2px solid transparent;
          border-radius: 5px;
          width: 60px;
          height: 60px; }
          .username-dialog .box.avatar-picker .inner .avatar img {
            max-width: 100%;
            max-height: 100%; }
          @media (max-width: 800px) {
            .username-dialog .box.avatar-picker .inner .avatar {
              padding: 10px; } }
          .username-dialog .box.avatar-picker .inner .avatar.selected {
            border: 2px solid black; }

button {
  font-family: "IBM Plex Mono", monospace;
  font-size: 90%;
  text-transform: uppercase;
  float: right;
  display: block;
  width: 100px;
  background: transparent;
  border: 1px solid black;
  color: black;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
  height: 30px;
  line-height: 20px; }
  button:hover {
    border: 1px solid #f0f0f0;
    background: #c5c5c5; }
</style>
