<div class="logo">
  <img src="/gc-logo.png" alt="Logo for Gamechangers"/>
</div>

<style lang="scss">.logo {
  position: fixed;
  width: auto;
  height: 70px;
  text-align: center;
  top: 12px;
  left: 12px;
  padding: 0;
  display: flex;
  user-select: none; }
</style>