<script>
  // # # # # # # # # # # # # #
  //
  //  Participants List Full
  //
  // # # # # # # # # # # # # #

  // *** IMPORTS
  import get from "lodash/get"
  import { renderBlockText } from "../sanity.js"

  // *** PROPS
  export let participants = {}

  // *** STORES
  import { globalSettings } from "../stores.js"
</script>

<div class="case-study-container">
  <!-- HEADER -->
  <div class="case-study-item header">
    <div class="inner">
      <div class="row">
        <div>GameChangers</div>
      </div>
    </div>
  </div>

  <!-- PEOPLE -->
  {#each participants as user, index (user._id)}
    <a
      class="case-study-item"
      href={"/people/" + get(user, "slug.current", "")}
    >
      <div class="inner">
        <div class="mid-section">
          <div class="title">{user.name}</div>
        </div>
      </div>
    </a>
  {/each}
</div>

<style lang="scss">.case-study-container {
  height: 100%;
  color: black;
  font-family: "IBM Plex Mono", monospace;
  font-size: 14px;
  background: #f0f0f0; }
  .case-study-container .case-study-item {
    padding: 0px 12px;
    padding-top: 12px;
    padding-bottom: 12px;
    width: 100%;
    background: #f0f0f0;
    color: black;
    display: inline-block;
    text-decoration: none;
    user-select: none;
    transition: background 0.5s cubic-bezier(0.23, 1, 0.32, 1); }
    .case-study-container .case-study-item .inner {
      width: 100%; }
      .case-study-container .case-study-item .inner .color-icon {
        display: none;
        height: 10px;
        width: 10px;
        margin-right: 18px;
        float: left;
        margin-top: 4px;
        background: #ed3656; }
        .case-study-container .case-study-item .inner .color-icon.communication {
          background: #1cc227; }
        .case-study-container .case-study-item .inner .color-icon.consensus-building {
          background: #a2dafd; }
        .case-study-container .case-study-item .inner .color-icon.sensing {
          background: #fff538; }
        .case-study-container .case-study-item .inner .color-icon.archiving {
          background: #f8c2e8; }
      .case-study-container .case-study-item .inner .mid-section {
        width: 60%;
        float: left; }
        .case-study-container .case-study-item .inner .mid-section .title {
          font-family: "IBM Plex Sans", sans-serif;
          font-weight: 500; }
        .case-study-container .case-study-item .inner .mid-section .participants {
          font-family: "IBM Plex Mono", monospace;
          pointer-events: none;
          color: #999999;
          font-size: 12px; }
      .case-study-container .case-study-item .inner .date {
        white-space: nowrap;
        color: #999999;
        float: right; }
    .case-study-container .case-study-item:hover {
      background: #c5c5c5; }
    .case-study-container .case-study-item.header {
      height: 45px;
      border-bottom: 1px solid #c5c5c5;
      background-color: white; }
      .case-study-container .case-study-item.header:hover {
        background: white !important; }
      .case-study-container .case-study-item.header.related {
        background-color: unset; }
        .case-study-container .case-study-item.header.related:hover {
          background: unset !important; }
      .case-study-container .case-study-item.header .archive-link {
        color: #999999;
        text-decoration: underline; }
      .case-study-container .case-study-item.header:hover {
        background: unset; }
    .case-study-container .case-study-item.related {
      padding-left: 0;
      padding-right: 0; }
      .case-study-container .case-study-item.related.header {
        height: 30px;
        padding-top: 0; }
  .case-study-container .description {
    padding: 12px; }
  .case-study-container .toolbar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 45px;
    padding: 12px;
    font-size: 12px;
    border-bottom: 1px dotted #c5c5c5;
    margin-bottom: 12px; }
    .case-study-container .toolbar .sort {
      height: 100%;
      display: flex;
      align-items: center; }
      .case-study-container .toolbar .sort select {
        margin-left: 6px;
        font-size: 12px;
        color: black;
        font-family: "IBM Plex Mono", monospace;
        border: none;
        background: #f0f0f0; }
    .case-study-container .toolbar .filter {
      height: 100%;
      display: flex;
      align-items: center; }
      .case-study-container .toolbar .filter svg {
        margin-right: 6px; }
        .case-study-container .toolbar .filter svg path {
          color: #c5c5c5; }
      .case-study-container .toolbar .filter input {
        width: 8ch;
        border: none;
        outline: none;
        font-size: 12px;
        color: black;
        font-family: "IBM Plex Mono", monospace;
        background: #f0f0f0; }
        .case-study-container .toolbar .filter input input[type="text"] {
          -webkit-appearance: none; }
        .case-study-container .toolbar .filter input select {
          -webkit-appearance: none; }
        .case-study-container .toolbar .filter input:focus {
          border-bottom: 1px solid black;
          width: 16ch; }
</style>
