<script>
  // # # # # # # # # # # # # #
  //
  //  Single Page
  //
  // # # # # # # # # # # # # #

  // *** IMPORTS
  import get from "lodash/get"
  import { fade } from "svelte/transition"
  import { renderBlockText } from "../sanity.js"
  import { links } from "svelte-routing"

  // *** COMPONENTS
  import MetaData from "../MetaData.svelte"

  // *** PROPS
  export let page = {}
</script>

<style lang="scss">.page-single .main-header {
  padding: 18px; }
  .page-single .main-header .title {
    font-family: "IBM Plex Sans", sans-serif;
    font-size: 14px;
    font-weight: bold; }
  .page-single .main-header .participants {
    margin-top: 6px;
    color: #999999;
    font-family: "IBM Plex Mono", monospace;
    font-size: 12px;
    display: inline-block; }

.page-single .divider {
  border-bottom: 1px dotted #c5c5c5;
  width: 100%; }

.page-single .text {
  padding: 18px 18px 0 18px;
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4em;
  letter-spacing: 0.02em; }
  @media (max-width: 800px) {
    .page-single .text {
      padding-bottom: 18px; } }

.page-single .related-events {
  padding: 18px; }
</style>

<!-- METADATA -->
<!-- <MetaData post={page} /> -->

<div class="page-single" in:fade use:links>
  <!-- HEADER -->
  <div class="main-header">
    <!-- TITLE -->
    <div class="title">{page.title}</div>
  </div>
  <div class="divider" />

  <!-- TEXT -->
  {#if Array.isArray(get(page, 'content.content', false))}
    <div class="text">
      {@html renderBlockText(page.content.content)}
    </div>
    <div class="divider" />
  {/if}

  <!-- RELATED pageS -->
  <!-- <div class="related-case-studies"> -->
  <!-- {await relatedpages then relatedpages} -->
  <!-- {#if Array.isArray(get(page, 'connectedpages', false))}
      <Calendar pages={page.connectedpages} related={true} />
    {/if} -->
  <!-- {/await} -->
  <!-- </div> -->
</div>
