<script>
  // # # # # # # # # # # # # #
  //
  //  Material Item
  //
  // # # # # # # # # # # # # #

  // *** IMPORTS
  import get from "lodash/get"
  import { urlFor } from "../sanity.js"

  // *** GLOBAL
  import { FORMATMAP } from "../global.js"

  // *** PROPS
  export let item = {}

  // *** VARIABLES
  let url = ""

  const makeUrl = ref =>
    "https://shape.anthropocene-curriculum.org/material/files/bu5rnal5/production/" +
    ref.substring(5).replace("-", ".")

  switch (item._type) {
    case "imageBlock":
      url = urlFor(get(item, "image.asset", {})).url()
      break
    case "audioBlock":
      url = makeUrl(get(item, "audioFile.asset._ref", ""))
      break
    case "fileBlock":
      url = makeUrl(get(item, "file.asset._ref", ""))
      break
    case "pdfBlock":
      url = makeUrl(get(item, "pdfFile.asset._ref", ""))
      break
    case "videoBlock":
      url = makeUrl(get(item, "videoFile.asset._ref", ""))
      break
    case "linkBlock":
      url = get(item, "url", "")
      break
  }
</script>

<style lang="scss">.material-item {
  width: 100%;
  background: #f0f0f0;
  color: black;
  display: block;
  text-decoration: none;
  user-select: none;
  padding-top: 6px;
  padding-bottom: 6px;
  font-size: 12px;
  cursor: pointer; }
  .material-item:first-of-type {
    margin-top: 6px; }
  .material-item .row {
    width: 100%;
    display: flex;
    justify-content: space-between; }
    .material-item .row .title {
      font-family: "IBM Plex Sans", sans-serif;
      font-weight: bold;
      white-space: nowrap;
      max-width: 80%;
      overflow: hidden;
      text-overflow: ellipsis; }
    .material-item .row .elips {
      margin-left: 6px;
      margin-right: 6px;
      width: 90%;
      white-space: nowrap;
      overflow: hidden;
      flex-shrink: 99999;
      color: #999999; }
    .material-item .row .format {
      white-space: nowrap;
      color: #999999; }
  .material-item:hover {
    background: #c5c5c5; }
  .material-item.header {
    border-bottom: 1px dotted #c5c5c5;
    cursor: default; }
    .material-item.header .archive-link {
      color: #999999;
      text-decoration: underline; }
    .material-item.header:hover {
      background: unset; }
</style>

<a href={url} target="_blank" class="material-item">
  <div class="row">
    <div class="title">{item.title}</div>
    <div class="elips">
      ....................................................................
    </div>
    <div class="format">
      {item._type === 'fileBlock' || item._type === 'linkBlock' ? item.fileType : FORMATMAP[item._type]}
    </div>
  </div>
</a>
