<script>
  // # # # # # # # # # # # # #
  //
  //  CARD
  //
  // # # # # # # # # # # # # #

  // *** IMPORT
  import { renderBlockText } from "./sanity.js"
  import get from "lodash/get"
  import { fade } from "svelte/transition"
  import { links } from "svelte-routing"

  // *** PROPS
  export let card = {}

  // *** VARIABLES
  let currentIndex = 0
</script>

<div class="card" use:links>
  {#each card.slides as slide, index (slide._key)}
    {#if Array.isArray(get(slide, "content", false)) && currentIndex === index}
      <div class="slide" in:fade|local>
        {@html renderBlockText(get(slide, "content", []))}
      </div>
    {/if}
  {/each}
  {#if card.slides && card.slides.length > 1}
    <div class="navigation">
      <div
        class="nav-button back"
        class:disabled={currentIndex === 0}
        on:click={e => {
          currentIndex -= 1
        }}
      >
        Back
      </div>
      <div
        class="nav-button next"
        class:disabled={currentIndex === card.slides.length - 1}
        on:click={e => {
          currentIndex += 1
        }}
      >
        Next
      </div>
    </div>
  {/if}
</div>

<style lang="scss">.card {
  padding: 12px;
  padding-top: 24px; }
  .card .navigation {
    display: flex;
    justify-content: center; }
    .card .navigation .nav-button {
      padding: 10px;
      background: #c5c5c5;
      border-radius: 4px;
      cursor: pointer;
      user-select: none; }
      .card .navigation .nav-button:hover {
        background: #bbbbbb; }
      .card .navigation .nav-button.back {
        margin-right: 10px; }
      .card .navigation .nav-button.disabled {
        opacity: 0.5;
        pointer-events: none; }
</style>
