<script>
  // # # # # # # # # # # # # #
  //
  //  Single Audio Installation
  //
  // # # # # # # # # # # # # #

  // *** IMPORTS
  import get from "lodash/get"
  import { fade } from "svelte/transition"
  import { renderBlockText } from "../sanity.js"
  import { links } from "svelte-routing"

  // *** COMPONENTS
  import ParticipantsList from "../lists/ParticipantsList.svelte"
  import MetaData from "../MetaData.svelte"

  // *** PROPS
  export let audioInstallation = {}
  export let audioInstallationLayer = {}

  // *** VARIABLES
  let expanded = false
  let playing = audioInstallation.noAutoplay ? false : true


  const installationsInstance = get(audioInstallationLayer, 'children', []).find(a => a.slug == get(audioInstallation, 'slug.current',''))  
  const audioPlayer = installationsInstance.audio

  const togglePlay = () => {
    if(audioPlayer.playing()) {
      audioPlayer.pause()
      playing = false
    } else {
      audioPlayer.play()
      playing = true
    }
  }

</script>

<style lang="scss">.audio-single .main-header {
  padding: 18px;
  position: relative;
  user-select: none;
  display: flex; }
  .audio-single .main-header .controls {
    margin-right: 12px;
    cursor: pointer; }
  .audio-single .main-header .title {
    font-family: "IBM Plex Sans", sans-serif;
    font-size: 14px;
    font-weight: bold; }
  .audio-single .main-header .expand {
    position: absolute;
    top: 12px;
    right: 12px;
    color: #999999;
    opacity: 0.4;
    cursor: pointer;
    transition: opacity 0.3s cubic-bezier(0.23, 1, 0.32, 1); }
    .audio-single .main-header .expand:hover {
      opacity: 0.7; }
  .audio-single .main-header .participants {
    margin-top: 6px;
    color: #999999;
    font-family: "IBM Plex Mono", monospace;
    font-weight: normal;
    font-size: 12px;
    display: inline-block; }

.audio-single .divider {
  border-bottom: 1px dotted #c5c5c5;
  width: 100%; }

.audio-single .date {
  padding: 18px;
  text-align: center;
  font-weight: 500;
  font-family: "IBM Plex Sans", sans-serif; }

.audio-single .image {
  width: 100%;
  padding-top: 18px;
  padding-bottom: 18px; }
  .audio-single .image img {
    width: 100%;
    max-height: 300px;
    object-fit: cover; }

.audio-single .text {
  padding: 18px 18px 0 18px;
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4em;
  letter-spacing: 0.02em; }
  @media (max-width: 800px) {
    .audio-single .text {
      padding-bottom: 18px; } }

.audio-single .connected-case-studies {
  padding: 18px; }
</style>

<!-- METADATA -->
<!-- <MetaData post={audioInstallation} /> -->

<div class="audio-single" in:fade use:links>
  {#if audioInstallation.title}
    <!-- HEADER -->
    <div class="main-header">
      <!-- CONTROLS -->
      <div
        class="controls"
        on:click={togglePlay}>
        {#if playing}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            viewBox="0 0 24 24"
            width="24">
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M6 19h4V5H6v14zm8-14v14h4V5h-4z" />
          </svg>
        {:else}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            viewBox="0 0 24 24"
            width="24"><path d="M0 0h24v24H0z" fill="none" />
            <path d="M8 5v14l11-7z" /></svg>
        {/if}
      </div>

      <div class="mid-section">
        <!-- TITLE -->
        <div class="title">{audioInstallation.title}</div>
        <!-- PARTICIPANTS -->
        {#if get(audioInstallation, 'participants', false) && Array.isArray(audioInstallation.participants)}
          <div class="participants">
            <ParticipantsList
              participants={audioInstallation.participants}
              messaging={true} />
          </div>
        {/if}
      </div>

      <!-- ARROW DOWN -->
      <div
        class="expand"
        on:click={() => {
          expanded = !expanded
        }}>
        {#if expanded}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="40"
            width="40"
            viewBox="0 0 24 24"
            ><path d="M0 0h24v24H0z" fill="none" />
            <path d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z" /></svg>
        {:else}
        <svg height="40"
        width="40" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40"><path d="M20,26.9a.71.71,0,0,0,.51-.23l11.2-10.08a.84.84,0,0,0-1.07-1.29l0,0L20,24.91,9.37,15.33a.83.83,0,1,0-1.15,1.21l0,0,11.2,10.08A.67.67,0,0,0,20,26.9Z"/></svg>
        {/if}
      </div>
    </div>

    <div class="divider" />

    {#if expanded}
      <!-- TEXT -->
      {#if Array.isArray(get(audioInstallation, 'content.content', false)) && audioInstallation.content.content.length > 0}
        <div class="text">
          {@html renderBlockText(audioInstallation.content.content)}
        </div>
        <div class="divider" />
      {/if}
    {/if}
  {/if}
</div>
